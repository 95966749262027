import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
} from '@material-ui/core'
import { useForm } from '../../../hooks/useForm'
import { createAxis } from '../../../services/axis'
import {
	addSnackbarNotification,
	withErrors,
} from '../../../services/snackbarNotification'

interface Props {
	open: boolean
	onClose?: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const NewAxisModal: React.FC<Props> = ({ open, onClose }) => {
	const [values, setValues, handleChange] = useForm<any>({
		name: '',
		excavation_method: 'NATM',
		thresholds: {
			warning: { y: 0, x: 0, z: 0 },
			danger: { y: 0, x: 0, z: 0 },
		},
		is_horizontal: true,
		has_free_points: true,
		short_code: '0',
	})

	return (
		<Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
			<DialogTitle>Nuevo Eje</DialogTitle>
			<DialogContent>
				<TextField
					value={values.name}
					onChange={handleChange}
					label='Nombre'
					fullWidth
					id='name'
					margin='normal'
					helperText='Ejemplo: Eje X'
				></TextField>
				<TextField
					value={values.short_code}
					onChange={handleChange}
					label='Número de eje'
					fullWidth
					id='short_code'
					margin='normal'
					helperText='Ejemplo: 01'
				></TextField>
				<FormControl>
					<InputLabel id='method-id'>Método de Excavación</InputLabel>
					<Select
						labelId='method-id'
						value={values.excavation_method}
						onChange={(e) => {
							setValues({
								...values,
								excavation_method: e.target.value,
							})
						}}
						label='Método de excavación'
						fullWidth
					>
						<MenuItem value='NATM'>
							NATM
						</MenuItem>
						<MenuItem value='TBM'>
							TBM
						</MenuItem>
						<MenuItem value='0'>Sin excavación</MenuItem>
					</Select>
				</FormControl>
				<div className='mt-1'>
					<div className=''>
						<FormControl fullWidth>
							<InputLabel id='orientation-label'>
								Orientación
							</InputLabel>
							<Select
								fullWidth
								labelId='orientation-label'
								value={values.is_horizontal ? 1 : 0}
								onChange={(e) =>
									setValues({
										...values,
										is_horizontal: !!e.target.value,
									})
								}
							>
								<MenuItem value={1}>Horizontal</MenuItem>
								<MenuItem value={0}>Vertical</MenuItem>
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel id='axisType-label'>
								Tipo de Eje
							</InputLabel>
							<Select
								fullWidth
								labelId='axis_type-label'
								value={values.axis_type}
								onChange={(e) => {
									setValues({
										...values,
										axis_type: e.target.value,
									})
								}}
							>
								<MenuItem value={"PIQUE"}>Pique</MenuItem>
								<MenuItem value={"GALERIA"}>Galería</MenuItem>
								<MenuItem value={"TUNEL"}>Túnel</MenuItem>
							</Select>
						</FormControl>
					</div>
					{/* <Checkbox
						checked={values.has_free_points}
						onChange={(e) =>
							setValues({
								...values,
								has_free_points: e.target.checked,
							})
						}
						label='Tiene puntos libres'
					></Checkbox> */}
				</div>
				<div className='text-center title-lg'>Umbrales[mm]</div>
				{/* <div className='w-full'>
					<div className='title-lg'>Normal</div>
					<div className='flex justify-between'>
						<div className='w-3'>
							<InputLabel>Norte</InputLabel>
							<TextField
								value={values.thresholds.caution.north}
								type='number'
								fullWidth
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											caution: {
												...values.thresholds.caution,
												north: parseFloat(
													e.target.value
												),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-3'>
							<InputLabel>Este</InputLabel>
							<TextField
								value={values.thresholds.caution.east}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											caution: {
												...values.thresholds.caution,
												east: parseFloat(
													e.target.value
												),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-3'>
							<InputLabel>Elevación</InputLabel>
							<TextField
								value={values.thresholds.caution.up}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											caution: {
												...values.thresholds.caution,
												up: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
					</div>
				</div> */}
				<div className='w-full bg-yellow-200'>
					<div className='title-lg'>Advertencia</div>
					<div className='flex w-full'>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Convergencia</InputLabel>
							<TextField
								value={values.thresholds.warning.x}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											warning: {
												...values.thresholds.warning,
												x: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Longitudinal</InputLabel>
							<TextField
								value={values.thresholds.warning.y}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											warning: {
												...values.thresholds.warning,
												y: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>

						<div className='w-1/3 flex flex-col'>
							<InputLabel>Elevación</InputLabel>
							<TextField
								value={values.thresholds.warning.z}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											warning: {
												...values.thresholds.warning,
												z: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
					</div>
				</div>

				<div className='w-full bg-red-200 '>
					<div className='title-lg'>Peligro</div>
					<div className='flex'>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Convergencia</InputLabel>
							<TextField
								value={values.thresholds.danger.x}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											danger: {
												...values.thresholds.danger,
												x: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
						<div className='w-1/3 flex flex-col'>
							<InputLabel>Longitudinal</InputLabel>
							<TextField
								value={values.thresholds.danger.y}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											danger: {
												...values.thresholds.danger,
												y: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>

						<div className='w-1/3 flex flex-col'>
							<InputLabel>Elevación</InputLabel>
							<TextField
								value={values.thresholds.danger.z}
								fullWidth
								type='number'
								onChange={(e) => {
									setValues({
										...values,
										thresholds: {
											...values.thresholds,
											danger: {
												...values.thresholds.danger,
												z: parseFloat(e.target.value),
											},
										},
									})
								}}
							></TextField>
						</div>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					onClick={async () => {
						withErrors(async () => {
							const aux = values
							aux.code = aux.short_code
							aux.thresholds = {
								warning: {
									y: values.thresholds.warning.y,
									x: values.thresholds.warning.x,
									z: values.thresholds.warning.z,
								},
								danger: {
									y: values.thresholds.danger.y,
									x: values.thresholds.danger.x,
									z: values.thresholds.danger.z,
								},
							}

							await createAxis(aux)
							addSnackbarNotification({ message: 'Eje creado' })
							onClose()
							setValues({
								name: '',
								excavation_method: 'NATM',
								thresholds: {
									warning: { y: 0, x: 0, z: 0 },
									danger: { y: 0, x: 0, z: 0 },
								},
								is_horizontal: true,
								has_free_points: true,
								short_code: '0',
							})
						})
					}}
				>
					Guardar
				</Button>
				<Button onClick={() => {
					onClose()

					setValues({
						name: '',
						excavation_method: 'NATM',
						thresholds: {
							warning: { y: 0, x: 0, z: 0 },
							danger: { y: 0, x: 0, z: 0 },
						},
						is_horizontal: true,
						has_free_points: true,
						short_code: '0',
					})

				}}>Cancelar</Button>
			</DialogActions>
		</Dialog>
	)
}
export default NewAxisModal
