import { List, MenuItem } from '@material-ui/core'
import { useState } from 'react'
import useStyles from '../../../styles/appStyles'
import AxesConfig from '../ConfigView/AxesConfig'
import PrismPointsConfig from '../ConfigView/PrismPointConfig'
import SectionsConfig from '../ConfigView/SectionsConfig'
import FileTypeConfig from './FileTypeConfig'
import ReportConfig from './ReportConfig'

const FilesView: React.FC = () => {
	const classes = useStyles(true)()
	const [tab, setTab] = useState<'FileTypes' | 'Files'>('Files')
	return (
		<div className={`${classes.bodyContent}`}>
			<div className='flex h-full'>
				<div className='w-1/6'>
					<h1 className='text-center title-lg'>Menú</h1>
					<List>
						<MenuItem
							selected={tab === 'Files'}
							onClick={() => setTab('Files')}
						>
							Reportes
						</MenuItem>
						<MenuItem
							selected={tab === 'FileTypes'}
							onClick={() => setTab('FileTypes')}
						>
							Tipos de Reporte
						</MenuItem>
					</List>
				</div>
				<div className='w-5/6 overflow-auto'>
					{tab === 'FileTypes' && <FileTypeConfig></FileTypeConfig>}
					{tab === 'Files' && <ReportConfig></ReportConfig>}
				</div>
			</div>
		</div>
	)
}
export default FilesView
